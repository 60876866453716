import { Component, OnDestroy, OnInit, Output, EventEmitter, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Chart } from 'chart.js';
import { finalize } from 'rxjs/operators';
import { ChartColor, ChartLegends, ISelectedTab } from 'src/app/models/EventDetail';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { EventService } from 'src/app/services/event.service';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { ChartsService } from 'src/app/services/charts.service';
import { FormatNumber } from 'src/app/shared/custom/FormatNumber';
import * as moment from 'moment';
import { UserSharedService } from 'src/app/shared/services/user-shared.service';
import { ChartOrder } from 'src/app/models/Event';
import { Sort } from '@angular/material/sort';
import { DOCUMENT } from '@angular/common';
import { TooltipPosition } from '@angular/material/tooltip';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UntypedFormControl } from '@angular/forms';
import { ChartOptions } from 'src/app/models/ChartJs';
import { FUNDRAISER_CREATION_LINK } from 'src/app/constant';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit, OnDestroy {
  @Output() getEventId: EventEmitter<any> = new EventEmitter<any>();
  getTooltipCustomFunc = (chartType: string, bulletPointColor: any) => {
    return {
      custom: (tooltipModel: any) => {
        let tooltipEl: any = this.document.getElementById('chartjs-tooltip');
        if (!tooltipEl) {
          tooltipEl = this.document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<table></table>';
          this.document.body.appendChild(tooltipEl);
        }

        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        tooltipEl.classList.remove('above', 'below', 'no-transform');

        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        const getBody = (bodyItem: any) => bodyItem.lines;

        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '<thead>';

          titleLines.forEach((title: string) => {
            innerHtml += '<tr class="tooltip-text" style="width: 100%;"><th>' + title + '</th></tr>';
          });

          innerHtml += '</thead><tbody>';

          bodyLines.forEach((body: any, i: number) => {
            let style = 'background:' + bulletPointColor[i];
            style += '; border-color:' + bulletPointColor[i];
            // style += '; border-width: 2px';
            const span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
            const splitContent = body[0]?.split(':');
            if (splitContent) {
              innerHtml += `<tr style="width: 100%;"><td style="display: flex;">${span}<span class="tooltip-text">${splitContent[0]}</span>&nbsp;<span class="tooltip-sub-text">${splitContent[1]}</span></td></tr>`;
            }
          });
          innerHtml += '</tbody>';

          const tableRoot = tooltipEl.querySelector('table');
          tableRoot.innerHTML = innerHtml;
        }
        
        const chart = chartType === 'event_marketing' ? this.marketingChart: this.revenueChart;
        const position = chart?.canvas?.getBoundingClientRect();
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        tooltipEl.style.padding = 6 + 'px ' + 8 + 'px';
        tooltipEl.style.pointerEvents = 'none';
      }
    }
  }
  
  public selectedNgo: SelectedNgo;
  public charityId: string;
  public charityIDSubscription: Subscription;
  private subscribeData: any;
  public eventId: number;

  public eventDetailsData;

  public revenueChart: any = null;
  public marketingChart: any = null;

  public chartColor = ChartColor;
  public chartLegends = ChartLegends;
  public chartOrder = ChartOrder;
  public chartData: any[] = [];

  public loading = false;
  public totalgoalevent: any;
  public percentage: any;
  public totalraised: any;
  public subscriberlistData: any;
  public activeSubscriberLoader = true;
  public allsourcesRaiseSum = 0;
  public totalParticipants = 0;
  public totalFundraisers = 0;
  public totalAverage_fundraiser = 0;
  public totalOptin = 0;
  public totalFundRaiserPer = 0;
  public toalDonations = 0;
  public totalFundraiserPercentage: any;
  public totalOptinPercent: any;
  public totalOptinPercentVal: any;
  public detailedData: any[] = [];
  public alertMsg = { type: '', msg: '' };

  public formatNumber = new FormatNumber();
  public startDate: any;
  public endDate: any;
  public diffDays: any;
  public onlyStartDate: any;
  public onlyEndDate: any;
  public user: any;
  public roles: any;
  public userRole: any;
  public userSubscription: Subscription;

  public sortingField: string;
  public orderBy: string;

  public config: any;
  public totalRecords: number;
  public currentPageCount: number;
  // public datasetFlag = {
  //   Raised: true,
  //   Campaigns: true,
  //   Donations: true,
  // };

  // public datasets = {
  //   Raised: null,
  //   Campaigns: null,
  //   Donations: null,
  // };

  public datasetFlag = {
    'Total Raised': true,
    'Total Campaigns': true,
    'Total Donations': true,
  };

  public datasets = {
    'Total Raised': null,
    'Total Campaigns': null,
    'Total Donations': null,
  };
  
  public newRepeatDatasets = {
    'Raised': 'total_raised',
    'Fundraisers': 'total_fundraiser',
    'Donations': 'total_donation',
  };

  public avgCampaign = 0;
  public avgDonation = 0;
  public avgDonations = 0;
  public currencyName: string;
  public revenueDataset:any = {};
  public marketingDataset:any = {};
  private commonChartOptions: ChartOptions = {
    responsive: true,
    hover: {
      intersect: true
    },
    legend: {
      onHover: (e) => {
        e.target.style.cursor = 'pointer';
      },
      display: false,
      labels: {
        usePointStyle: true,
        fontColor: 'rgba(0,44,69, .6)',
        fontSize: 12,
        fontStyle: 'bold',
        fontFamily: 'Poppins',
      }
    },
    tooltips: {
      enabled: false,
      mode: 'index',
      position: 'nearest',
      backgroundColor: '#718e9f',
      footerFontStyle: 'normal',
      titleFontFamily: 'Poppins',
      titleFontSize: 12,
      titleMarginBottom: 10,
      bodyFontFamily: 'Poppins',
      bodyFontSize: 12,
      bodySpacing: 5,
      callbacks: {
        title: (tooltipItems) => {
          if (tooltipItems?.length) {
            const firstItem = tooltipItems[0];
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const dateAndMonth = firstItem?.label?.split(`'`);
            const monthIndex = months?.findIndex(m => m?.toLowerCase()?.startsWith(dateAndMonth[1]?.toLowerCase()));
      
            if (monthIndex < 0) return '';
            return `${months[monthIndex]} ${dateAndMonth[0]} `;
          } else {
            return '';
          }
        },
        label: (tooltipItems, data) => {
          let label: string = data.datasets[tooltipItems.datasetIndex].label || '';
          label = label.split(' ')[0];
          let currencySymbol = this.selectedNgo.currency_name == 'USD' ? ' $' : ' £';
      
          if (
            data.datasets[tooltipItems.datasetIndex].label.includes('Raised')
          ) label += ': ' + currencySymbol;
          else label += ': ';
      
          if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
          else label += 0;
          return label;
        },
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            drawBorder: true,
            drawTicks: true,
            drawOnChartArea: false,
          }
        }
      ],
      yAxes: [],
    },
    elements: {
      point: {
        radius: 1.5
      },
      line: {
        tension: 0
      }
    }
  }
  private eventRevenueBulletPoints = ['#318DE2', '#00A8AE'];
  private eventMarketingBulletPoints = ['#318DE2', '#00A8AE'];
  private eventMarketingLine = [
    {
      name: 'ROAS',
      borderColor: '#318DE2',
      backgroundColor: '#318DE2',
    },
    {
      name: 'CPL',
      borderColor: '#04C3CB',
      backgroundColor: '#04C3CB',
    }
  ];
  private eventRevenueLineArea = [
    {
      backgroundColor: '#90bee8',
      borderColor: '#ADD1F3',
      name: 'Total Raised'
    },
    {
      backgroundColor: '#B4EDF0',
      borderColor: '#04C3CB',
      name: 'Total Campaigns'
    },
    {
      backgroundColor: '#B4EDF0',
      borderColor: '#04C3CB',
      name: 'Total Donations'
    }
  ];
  private transparentColor: string = '#ffffff00';
  private newDataBgColor:string = 'rgba(173, 209, 243, 0.2)';
  private newDataBcColor:string = '#318DE2';
  private repeatDataBcColor:string = '#00A8AE';
  private repeatDataBgColor:string = 'rgba(130, 225, 229, 0.2)';
  public eventRevenueOptions: ChartOptions = {
    ...JSON.parse(JSON.stringify(this.commonChartOptions)),
    ...({
      tooltips: { 
        ...this.commonChartOptions.tooltips, 
        ...this.getTooltipCustomFunc('event_revenue', this.eventRevenueBulletPoints)
      }
    })
  };
  public eventMarketingOptions: ChartOptions = {
    ...JSON.parse(JSON.stringify(this.commonChartOptions)),
    ...({ 
      tooltips: { 
        ...this.commonChartOptions.tooltips, 
        ...this.getTooltipCustomFunc('event_marketing', this.eventMarketingBulletPoints)
      }
    })
  };

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);
  selectedTab: ISelectedTab = {
    marketing: 0,
    revenue: 0
  };

  eventMarketingData: any = {
    cpl: 100,
    roas: 1000
  }
  newDataObj:any = {};
  repeatDataObj:any = {};
  newDataMarketing:any = {};
  repeatDataMarketing:any = {};
  oneClickLink:string = '';
 
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _ngoSharedService: NgoSharedService,
    private _eventService: EventService,
    private _chartService: ChartsService,
    private _userSharedService: UserSharedService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 5 ||
      document.documentElement.scrollTop > 5) {
      document.getElementById('subscriberList').classList.add('sideContent');
    } else {
      document.getElementById('subscriberList').classList.remove('sideContent');
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.getCharityId();
    this.getUserinfo();
  }

  getUserinfo() {
    this.userSubscription = this._userSharedService
      .userData
      .subscribe(
        res => {
          if (Object.keys(res).length > 0) {
            this.user = res;
            this.roles = this.user.roles;
            this.userRole = this.roles[0].role;
          }
        }
      );
  }

  ngoChanged(ngo: SelectedNgo) {
    this.router.navigate(['/']);
  }

  redirectChallenges() {
    this.router.navigate(['/events']);
  }

  getEventIdData() {
    this.subscribeData = this.route.params.subscribe(params => {
      this.eventId = +params.event_id;
    });
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
      .selectedNgo
      .subscribe(
        selectedNGO => {
          if (selectedNGO.charityId !== null) {
            this.currencyName = selectedNGO.currency_name;
            this.selectedNgo = selectedNGO;
            this.charityId = selectedNGO.charityId;
            this.getEventIdData();
            this.getEventDetails();
            this.getEventDetailSubscriberListData();
            
          }
        },
        err => this.showAlertMsg(err.message, 'danger')
      );
  }

  getEventDetails() {
    this.loading = true;
    this.eventRevenueOptions.scales.yAxes = [];
    this._eventService
      .getEventDetail(this.charityId, this.eventId)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe(
        (res: any) => {
          
          this.eventDetailsData = res;
          this.oneClickLink = FUNDRAISER_CREATION_LINK + this.eventDetailsData?.overall?.event_identifier;            
          this.eventDetailsData.data.forEach((val, i) => this.datasetFlag[val.title] = true);
          
          this.createRevenueChart(res?.event_detail_graph);
          this.getEventMarketingDetails(res?.roas_cpl_graph);
          if (this.eventDetailsData.overall.total_campaign_num)
            this.avgCampaign = Math.round((this.eventDetailsData.overall.total_raised / this.eventDetailsData.overall.total_campaign_num));
          if (this.eventDetailsData.overall.total_donation_num)
            this.avgDonation = Math.round((this.eventDetailsData.overall.total_raised / this.eventDetailsData.overall.total_donation_num));
          if (this.eventDetailsData.overall.total_campaign_num)
            this.avgDonations = Math.round((this.eventDetailsData.overall.total_donation_num / this.eventDetailsData.overall.total_campaign_num));

          this.detailedData = res.detailed_data;
          this.sortingField = 'attribution_source';
          this.orderBy = 'asc';

          // tslint:disable-next-line: max-line-length
          this.eventDetailsData.detailed_data = res.detailed_data.sort((a, b) => this.compare(a.attribution_source, b.attribution_source, true));

          if (!this.detailedData) return;
          if (this.detailedData.length > 0) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < this.detailedData.length; i++) {
              this.allsourcesRaiseSum += this.detailedData[i]?.total_raised || 0;
              this.totalParticipants += this.detailedData[i]?.participants || 0;
              this.totalFundraisers += this.detailedData[i]?.fundraisers || 0;
              this.toalDonations += this.detailedData[i]?.total_donations || 0;
              this.totalFundRaiserPer += this.detailedData[i]?.fundraiser_percentage || 0;
              this.totalAverage_fundraiser = this.allsourcesRaiseSum / this.totalFundraisers;
              this.totalOptin += this.detailedData[i]?.total_optins || 0;
            }
            if (this.detailedData.length > 0) {
              if (isNaN(this.totalOptin)) this.totalOptinPercent = 0;
              else this.totalOptinPercent = ((this.totalOptin) / (this.totalParticipants) * 100);
            }
            this.totalFundraiserPercentage = ((this.totalFundraisers / this.totalParticipants) * 100);
            if ((isNaN(this.totalFundraiserPercentage)) || this.totalFundraiserPercentage == 'Infinity') this.totalFundraiserPercentage = 0;
            if (isNaN(this.totalAverage_fundraiser)) this.totalAverage_fundraiser = 0;
          }

          if (
            !this.eventDetailsData &&
            !this.eventDetailsData.hasOwnProperty('overall') &&
            !this.eventDetailsData.overall.hasOwnProperty('total_raised')
          ) return;

          this.totalraised = this.eventDetailsData.overall.total_raised;
          this.totalgoalevent = this.eventDetailsData.overall.event_goal;
          this.percentage = ((this.totalraised / this.totalgoalevent) * 100).toFixed(2);
          this.startDate = moment(this.eventDetailsData.overall.start_date);
          this.endDate = moment(this.eventDetailsData.overall.end_date);
          this.diffDays = Math.abs(this.endDate.diff(this.startDate, 'days'));
          this.onlyStartDate = moment(this.startDate).format('MM/DD/YYYY');
          this.onlyEndDate = moment(this.endDate).format('MM/DD/YYYY');
        },
        err => {
          if (err.status === 400) return this.showAlertMsg(err.message.event_id[0], 'danger');
          this.router.navigate(['/events/']);
        }
      );
  }

  // TODO: API Logic
  getEventMarketingDetails = async(data) => {
    try {
      this.createMarketingChart(data);
    } catch(error) {

    }
  }

  createRevenueChart(data: any) {
    this.revenueChart = null;
    const labels = this.getLabels(data?.label);
    const datasets = this.getRevenueDatasets(data?.data);
    this.changeNewAndRepeatData('Raised','event_revenue');
    this.revenueChart = new Chart('event_revenue', {
      type: 'line',
      data: {
        labels,
        datasets: [...datasets['Raised']]     
      },
      options: this.eventRevenueOptions
    });
  }

  createMarketingChart(data: any) {
    this.marketingChart = null;
    const labels = this.getLabels(data?.label);
    const datasets = this.getMarketingDatasets(data?.data);
    this.changeNewAndRepeatData('ROAS','event_marketing');
    this.marketingChart = new Chart('event_marketing', {
      type: 'line',
      data: {
        labels,
        datasets:[...datasets['ROAS']]
      },
      options: this.eventMarketingOptions
    });
  }


  getLabels(labelList: any[]): string[] {
    return labelList?.length ? labelList : [];
  }

  getRevenueDatasets(dataList: any): any[] {
    const yAxesConfig = this._chartService.createYaxesConfig();
  
    dataList?.forEach((item: any) => {
      // const selectedItem = this.eventRevenueLineArea[this.selectedTab?.revenue];
      this.revenueDataset[this.chartLegends[item?.title]] = [];
      const newdataset = {
        label: 'New',
        data: item?.new,
        backgroundColor: this.newDataBgColor,
        borderColor: this.newDataBcColor,
        fill: true,
        borderWidth: 2,
        lineTension: 0,
        yAxisID: '',
        hidden: false
      };
      const repeatdataset = {
        label: 'Repeat',
        data: item?.repeat,
        backgroundColor: this.repeatDataBgColor,
        borderColor: this.repeatDataBcColor,
        fill: true,
        borderWidth: 2,
        lineTension: 0,
        yAxisID: '',
        hidden: false
      };
      
      if (
        ['Total Campaigns', 'Total Donations']?.includes(item.title)
      ) {
        newdataset.yAxisID = 'left-y-axis';
        newdataset.hidden = false;

        repeatdataset.yAxisID = 'left-y-axis';
        repeatdataset.hidden = false;
        if (item.title === 'Total Campaigns') this.setRightYAxis('left-y-axis', 'right', false);
        this._chartService.updateYaxesMinMax(yAxesConfig, 'left-y-axis', newdataset?.data);
        this._chartService.updateYaxesMinMax(yAxesConfig, 'left-y-axis', repeatdataset?.data);
      } else {
        this.setRightYAxis('right-y-axis', 'right', true);
        newdataset.yAxisID = 'right-y-axis';
        newdataset.hidden = false;

        repeatdataset.yAxisID = 'right-y-axis';
        repeatdataset.hidden = false;
        this._chartService.updateYaxesMinMax(yAxesConfig, 'right-y-axis', newdataset?.data);
        this._chartService.updateYaxesMinMax(yAxesConfig, 'right-y-axis', repeatdataset?.data);
      }
      this.revenueDataset[this.chartLegends[item?.title]].push(newdataset);
      this.revenueDataset[this.chartLegends[item?.title]].push(repeatdataset);
      // return dataset;
    });
    this.setCustomTickConfig(yAxesConfig);
    return this.revenueDataset;
  }

  getMarketingDatasets(dataList: any): any[] {
    dataList?.forEach((item: any, index: number) => {
      // const selectedItem = this.eventMarketingLine[this.selectedTab?.marketing];
      this.marketingDataset[item?.title] = [];
      const newdataset = {
        label: 'New',
        data: item?.new,
        backgroundColor: this.newDataBgColor,
        borderColor: this.newDataBcColor,
        fill: false,
        borderWidth: 2,
        lineTension: 0,
        yAxisID: '',
        hidden: false
      };

      const repeatdataset = {
        label: 'Repeat',
        data: item?.repeat,
        backgroundColor: this.repeatDataBgColor,
        borderColor: this.repeatDataBcColor,
        fill: false,
        borderWidth: 2,
        lineTension: 0,
        yAxisID: '',
        hidden: false
      };
      this.setCurrencyYaxis('left-y-axis', 'left',true)
      this.marketingDataset[item?.title].push(newdataset);
      this.marketingDataset[item?.title].push(repeatdataset);
      // return dataset;
    });
    return this.marketingDataset;
  }

  getTotalValue(title: string) {
    if (!(this.eventDetailsData && this.eventDetailsData.hasOwnProperty('data') && this.eventDetailsData.data)) return;

    let sum = 0;
    const data: any = this.eventDetailsData.data.filter(val => val.title === title);
    if (data.length < 1) return;

    data[0].value.forEach(val => {
      if (!isNaN(val)) sum += parseInt(val, 10);
    });

    if (title === 'Total Campaigns' || title === 'Total Donations') return ' ' + sum.toLocaleString();
    let currencySymbol = this.selectedNgo.currency_name == 'USD' ? ' $' : ' £';
    return currencySymbol + Math.round(sum).toLocaleString();
  }

  setRightYAxis(id: string, position: string, stacked: boolean) {
    this.eventRevenueOptions.scales.yAxes.push({
      id,
      stacked,
      type: 'linear',
      display: stacked,
      position,
      gridLines: {
        display: true,
        drawBorder: false,
        drawTicks: false,
        drawOnChartArea: true,
        lineWidth: 1
      },
      ticks: {
        beginAtZero: true,
        fontColor: '#7F95A1',
        fontSize: 11,
        fontFamily: 'Roboto',
        fontStyle: 'Regular',
        padding: 10,
        callback: (value, index, values) => {
          const currencySymbol = this.selectedNgo.currency_name == 'USD' ? ' $' : ' £';
          if (stacked) return currencySymbol + this._chartService.formatNumber(value) + ' ';
          else return ' ' + this._chartService.formatNumber(value);
        },
      },
    });
  }

  setCurrencyYaxis(id: string, position: string,stacked:boolean){
    this.eventMarketingOptions.scales.yAxes.push({
      id,
      type: 'linear',
      display: stacked,
      position,
      gridLines: {
        display: stacked,
        drawBorder: false,
        drawTicks: false,
        drawOnChartArea: stacked,
        lineWidth: 1
      },
      ticks: {
        beginAtZero: true,
        fontColor: '#7F95A1',
        fontSize: 11,
        fontFamily: 'Roboto',
        fontStyle: 'Regular',
        padding: 10,
        callback: (value, index, values) => {
          const currencySymbol = this.selectedNgo.currency_name == 'USD' ? ' $' : ' £';
          return currencySymbol + this._chartService.formatNumber(value) + ' ';
        },
      },
    });
    
  }

  getEventDetailSubscriberListData() {
    this.activeSubscriberLoader = true;
    this._eventService
      .eventDetailsSubscirberList(this.charityId, this.eventId)
      .pipe(finalize(() => this.activeSubscriberLoader = false))
      .subscribe(
        (res: any) => {
          if (typeof res === 'string') this.subscriberlistData = [];
          else {
            this.subscriberlistData = res.map((val) => {
              const newData = Object.assign({}, val);
              newData.fundraiser_amount = this.formatNumber.format(val.fundraiser_amount);
              return newData;
            });
          }
        }
      );
  }

  sortData(sort: Sort) {
    this.sortingField = sort.active;
    this.orderBy = sort.direction;

    // tslint:disable-next-line: max-line-length
    const data = this.eventDetailsData.detailed_data && this.eventDetailsData.detailed_data.length > 0 ? this.eventDetailsData.detailed_data.slice() : [];

    if (!sort.active || sort.direction === '') return this.eventDetailsData.detailed_data = data;

    this.eventDetailsData.detailed_data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'attribution_source': return this.compare(a.attribution_source, b.attribution_source, isAsc);
        case 'total_raised': return this.compare(a.total_raised, b.total_raised, isAsc);
        case 'participants': return this.compare(a.participants, b.participants, isAsc);
        case 'fundraiser_percentage': return this.compare(a.fundraiser_percentage, b.fundraiser_percentage, isAsc);
        case 'average_fundraiser': return this.compare(a.average_fundraiser, b.average_fundraiser, isAsc);
        case 'total_donations': return this.compare(a.total_donations, b.total_donations, isAsc);
        case 'opt_in_percentage': return this.compare(a.opt_in_percentage, b.opt_in_percentage, isAsc);
        default: return 0;
      }
    });

  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    if (typeof (a) === 'string' && typeof (b) === 'string') {
      a = a.toLowerCase();
      b = b.toLowerCase();
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg = msg;
    this.alertMsg.type = type;
    this.loading = false;

    setTimeout(() => this.alertMsg.msg = '', 3000);
  }

  editEvent() {
    const queryParams = { eventId: this.eventId };
    this.router.navigate(['/events/edit'], { queryParams });
  }

  openSubscriberDetail(_id) {
    this.router.navigate(['/subscribers/detail', _id]);
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.subscribeData) this.subscribeData.unsubscribe();
    if (this.userSubscription) this.userSubscription.unsubscribe();
  }

  setCustomTickConfig(yAxesConfig: any) {
    const maxNumSpaces = yAxesConfig?.totalTicks - 1;
    const leftYaxesConfig = this._chartService.getPreCalculatedTickConfig(yAxesConfig['left-y-axis'], maxNumSpaces);
    const rightYaxesConfig = this._chartService.getPreCalculatedTickConfig(yAxesConfig['right-y-axis'], maxNumSpaces);
    this._chartService.equalizeTickConfig(leftYaxesConfig, rightYaxesConfig);

    for (let i = 0; i < this.eventRevenueOptions.scales.yAxes?.length; i++) {
      const item = this.eventRevenueOptions.scales.yAxes[i];
      if (item?.id === 'left-y-axis') {
        this.eventRevenueOptions.scales.yAxes[i].ticks.min = leftYaxesConfig?.min;
        this.eventRevenueOptions.scales.yAxes[i].ticks.max = leftYaxesConfig?.max;
        this.eventRevenueOptions.scales.yAxes[i].ticks.stepSize = leftYaxesConfig?.spacing;
      } else if (item?.id === 'right-y-axis') {
        this.eventRevenueOptions.scales.yAxes[i].ticks.min = rightYaxesConfig?.min;
        this.eventRevenueOptions.scales.yAxes[i].ticks.max = rightYaxesConfig?.max;
        this.eventRevenueOptions.scales.yAxes[i].ticks.stepSize = rightYaxesConfig?.spacing;
      }
    }
  }

  onRevenueTabChange(tabId: MatTabChangeEvent) {
    this.selectedTab.revenue = tabId?.index;
    const tabList = ['Raised','Fundraisers','Donations'];
    // Update datasets
    // const datasets = this.revenueChart?.data?.datasets?.map((dataset: any, index: number) => {
    //   return {
    //     ...dataset,
    //     ...this.eventRevenueLineArea[tabId?.index],
    //     ...(index !== tabId?.index && {
    //       backgroundColor: this.transparentColor,
    //       borderColor: this.transparentColor
    //     })
    //   }
    // });
    
    this.changeNewAndRepeatData(tabList[tabId?.index],'event_revenue');
    
    const datasets = this.revenueDataset[tabList[tabId?.index]];
    this.revenueChart.data.datasets = datasets;

    // Update chart options
    const getTabYaxes = () => {
      switch (tabId?.index) {
        case 0:
          return 'right-y-axis';
        default:
          return 'left-y-axis';
      }
    }
    const modifiedYaxes = this.revenueChart?.options?.scales?.yAxes?.map((item: any) => {
      return {
        ...item,
        display: item?.id === getTabYaxes()
      }
    });
    this.revenueChart.options.scales.yAxes = modifiedYaxes;
    // this.revenueChart?.updateDatasets();
    this.revenueChart?.update();
  }

  onMarketingTabChange(tabId: MatTabChangeEvent) {
    this.selectedTab.marketing = tabId?.index;
    // const datasets = this.marketingChart?.data?.datasets?.map((dataset: any) => {
    //   const selectedItem = this.eventMarketingLine[this.selectedTab.marketing];
    //   return {
    //     ...dataset,
    //     borderColor: this.transparentColor,
    //     backgroundColor: this.transparentColor,
    //     ...(dataset?.label === selectedItem?.name && selectedItem),
    //   }
    // });
    const tabList = ['ROAS','CPL'];
    this.changeNewAndRepeatData(tabList[tabId?.index],'event_marketing');

    const datasets = this.marketingDataset[tabList[tabId?.index]];
    this.marketingChart.data.datasets = datasets;
    // this.marketingChart?.updateDatasets();
    this.marketingChart?.update();
  }

  // To get value & percentage for [New & Repeat] for [revenue/marketing] graph
  changeNewAndRepeatData(tab:string,event:string) {
   
    switch (event) {
      case 'event_revenue':
        const key = this.newRepeatDatasets[tab];
        const newVal = this.eventDetailsData?.event_detail_graph?.graph_details[key]?.new;
        const repeatVal = this.eventDetailsData?.event_detail_graph?.graph_details[key]?.repeat;
        const totalVal = (newVal + repeatVal);

        this.newDataObj.key = key;
        this.repeatDataObj.key = key;

        this.newDataObj.val = newVal;
        this.repeatDataObj.val = repeatVal;
    
        this.newDataObj.percentage = (newVal / totalVal) * 100 || 0;
        this.repeatDataObj.percentage = (repeatVal / totalVal) * 100 || 0;        
        break;

      case 'event_marketing':
        const newRepeatMArketingDatasets = {
          'CPL': 'total_cpl',
          'ROAS': 'total_roas'
        };
        const marketingkey = newRepeatMArketingDatasets[tab]; 
        const newMarketingVal = this.eventDetailsData?.roas_cpl_graph?.graph_details[marketingkey]?.new;
        const repeatMarketingVal = this.eventDetailsData?.roas_cpl_graph?.graph_details[marketingkey]?.repeat;
        const totalMarketingVal = (newMarketingVal + repeatMarketingVal);
        
        this.newDataMarketing.val = newMarketingVal;
        this.repeatDataMarketing.val = repeatMarketingVal;
    
        this.newDataMarketing.percentage = (newMarketingVal / totalMarketingVal) * 100 || 0;
        this.repeatDataMarketing.percentage = (repeatMarketingVal / totalMarketingVal) * 100 || 0;
        break;
    
      default:
        break;
    }

  }

}