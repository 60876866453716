<div class="above fade confirmBoxwrapper" >
    <div class="custom-dialog-modal modal-md" role="document">
        <div class="m-2">
            <button type="button" class="closeDatePicker close" (click)="dismisConfirmModal('cancel')">&times;</button>
            <div class="modal-body-new">
                <div class="form-wrapper">
                    <div class="row no-gutters custom-rwo-wrapper text-center"> 
                        <div class="col-12">
                            <h1>Leave without saving?</h1>
                            <p>You have some changes that haven’t been saved to this report.</p>
                        </div>
                        <div class="col-12 col-md-12 text-center">                           
                            <button type="button" class="error-text-md-btn-text mr-2" (click)="dismisConfirmModal('continue')" >Discard Changes</button>
                            <button type="button" class="accent-filled-md-btn-text" (click)="dismisConfirmModal('save')">Save Changes </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>