<div class="errBGbox">  
    
  <div class="card-box text-center">

    <div class="while-logo">
      <img src="../../assets/images/GU_Logo_Primary_Color.svg" alt="">
    </div>
    <p class="errorTxt mb-5">
      Unrecognized login credentials<br/>Access requires an invitation. If you are a new user, please contact your administrator.
    </p>
    <div class="bckTolgn"><a (click) = "backToLogin()" href="javascript:void(0)">Back To Login</a></div>
  </div>


  <div class="login-footer">
    <p>Copyright © 2022 GoodUnited.io</p>
  </div>  
</div>
