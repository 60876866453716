<div class="overlay__inner custom-overlay" *ngIf="subscriptionLoader">
    <div class="overlay__content">
        <span class="spinner loadImg"></span>
    </div>
</div>

<ng-container *ngIf="subscriptionGraphData && subscriptionGraphData.data; else notfound">

<h2 class="common-heading mr-3"><span [matTooltipClass]="positionBottom.value" matTooltip="Subscriber list showing all Subscribers growth over time.">Subscribers</span></h2>

<div class="stat-row" *ngIf="subscriptionGraphData">
    <div class="stat-blocks">
        <div class="stat-block">
            <div class="stat-label-wrap" style="justify-content:left;">
                <!-- <div class="stat-color primary"></div> -->
                <div class="stat-label" [matTooltipClass]="positionBottom.value" matTooltip="Total amount of Subscribers within the selected date period.">Total Subscribers</div>
            </div>
            <div class="stat-number subgraphs">
                {{ subscriptionGraphData?.overall?.totalOptins | number:'1.0-0' }}
            </div>
            <div class="trend" [ngClass]="(subscriptionGraphData?.trend > 0)?'upward':'downward'">                
                <div class="text-block-27" *ngIf=" allTime == 'daily' ">
                    ({{ subscriptionGraphData?.trend }}%) <i class="fa strend" [ngClass]="(subscriptionGraphData?.trend > 0)?'fa-sort-asc':'fa-sort-desc'" aria-hidden="true"></i>
                </div>
                <!-- <img src="../../assets/images/uptrend.svg" alt="" class="image-7"> -->
                
            </div>
        </div>
    </div>
</div>

<div class="html-embed-9 w-embed w-script" *ngIf="subscriptionGraphData">
    <div class="row no-gutters">
        <div class="col-12 text-left">
            <ul class="legends frgraphLegend">
                <li (click)="datasetFlag.Direct ? removeDataset('Direct') : addDataset('Direct')" [ngClass]="{'cross-line': !datasetFlag.Direct}">
                <div class="legendBox">
                    <div class="lgDisc prBg mr-1"></div>
                    <div class="lgText lgFont mr-1" matTooltip="Total amount of subscribers from GU Direct product (birthdays), within the selected time period.">Direct</div>
                    <div class="lgFont prColor">
                        {{ getTotalValue('Direct') }}
                    </div>
                </div>  
                </li>
                <li (click)="datasetFlag.Challenges ? removeDataset('Challenges') : addDataset('Challenges')" [ngClass]="{'cross-line': !datasetFlag.Challenges}">
                    <div class="legendBox">
                        <div class="lgDisc orBg mr-1"></div>
                        <div class="lgText lgFont mr-1" matTooltip="Total amount of subscribers from GU Facebook Challenge product, within the selected time period.">Challenges</div>
                        <div class="lgFont orColor">
                            {{ getTotalValue('Challenges') }}
                        </div>
                    </div> 
                </li>
            </ul>
        </div>
    </div>

    <canvas id="subscription" width="400" height="400"></canvas>
</div>
</ng-container>

<ng-template #notfound>
    <h2 class="common-heading mr-3">
        <span [matTooltipClass]="positionBottom?.value" matTooltip="Subscriber list showing all subscribers growth over time.">Subscribers</span>
    </h2> 
  <div class="nofound"><p>No Data Found</p></div>
</ng-template>