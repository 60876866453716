import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import * as momentJs from 'moment';
import { CustomValidators } from 'src/app/shared/custom/CustomValidators';
import { CustomValidationMessages } from 'src/app/shared/custom/custom-validation-messages';
import { EventService } from 'src/app/services/event.service';
import { CommonService } from 'src/app/services/common.service';
import { AddProjectImageResponse } from 'src/app/models/Common';
import { Subscription } from 'rxjs';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { EventSeriesActivities } from 'src/app/models/Event';
import { MomentDate } from 'src/app/models/Date';
import { CreateEventFormErrors } from './FormErrorVar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-events',
  templateUrl: './new-events.component.html',
  styleUrls: ['./new-events.component.scss']
})
export class NewEventsComponent implements OnInit, OnDestroy {
  @ViewChild('modalCloseAddSeries') modalCloseAddSeries: ElementRef<HTMLElement>;
  @ViewChild('modalCloseaddActivity') modalCloseaddActivity: ElementRef<HTMLElement>;

  public charityId: string;
  public charityIDSubscription: Subscription;

  public eventListSeriesActivities: EventSeriesActivities;

  public affectcount = 0;
  public createEventForm: UntypedFormGroup;

  public MomentDate = new MomentDate();
  public monthList = this.MomentDate.months;
  public months = [];

  public fileToUpload: File;
  public base64: string;
  public imageUploadedData: AddProjectImageResponse;
  public imagePreview;

  public alertMsg = { type: '', msg: ''};
  public loader = false;

  public startMinDate = momentJs();
  public endMinDate = momentJs();
  public locale = this.MomentDate.eventLocaleOptions;

  public validationMessages = CustomValidationMessages.validationMessages;

  public formErrors = CreateEventFormErrors;
  public formErrorMsg = '';
  public createSeriesForm: UntypedFormGroup;
  public createActivityForm: UntypedFormGroup;
  public crateSeriesSubscription: Subscription;
  public crateActivitySubscription: Subscription;
  public submitted = false;
  public chkCurrency:any;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private _ngoSharedService: NgoSharedService,
    private _eventService: EventService,
    private _commonService: CommonService,
  ) { }

  ngOnInit() {
    this.monthList.forEach(val => {
      this.months.push({
        index: this.MomentDate.monthInt(val),
        name: val
      });
    });
    this.initialiseCreateEventForm();
    this.initialiseCreateSeriesForm();
    this.initialiseActivity();
    this.createEventForm.valueChanges.subscribe(data => this.logValidationErrors(this.createEventForm));

    this.getCharityId();
  }

  initialiseCreateEventForm() {
    this.createEventForm = this.fb.group({
      event_name:         ['', [Validators.required, CustomValidators.spaceValidator]],
      start_date:         ['', [Validators.required]],
      end_date:           ['', [Validators.required]],
      month:              ['', [Validators.required]],
      fundraiser_goal:    ['', [Validators.required, CustomValidators.onlyNumberValidator, CustomValidators.zeroValidator]],
      opt_in_url:         ['', [Validators.required, CustomValidators.urlValidator]],
      group_url:          ['', [Validators.required, CustomValidators.urlValidator]],
      event_identifier:   ['', [Validators.required, CustomValidators.spaceValidator]],
      event_series_id:    ['', [Validators.required]],
      activity_details: this.fb.group({
        activity_label:   ['', [Validators.required]],
        activity_goal:    ['', [Validators.required, CustomValidators.onlyNumberValidator, CustomValidators.zeroValidator]]
      }),
      activity_markers: this.fb.group({
        fifty_activity_mark:    ['', [Validators.required, CustomValidators.urlValidator]],
        hundred_activity_mark:  ['', [Validators.required, CustomValidators.urlValidator]]
      })
    });
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.charityId  = selectedNGO.charityId;
              this.chkCurrency = selectedNGO.currency_name=='USD' ? "$0" : "£0";
              this.getEventListSeriesActivities();
            }
          },
          err => this.showAlertMsg('Charity ID Not Found', 'danger')
        );
  }

  getEventListSeriesActivities() {
    this._eventService
        .eventListSeriesActivities(this.charityId)
        .subscribe(
          (res: any) => this.eventListSeriesActivities = res,
          err => this.eventListSeriesActivities = null
        );
  }

  setEndDate(ev) {
    if (!ev.startDate) return;

    this.endMinDate = momentJs(ev.startDate._d);
    this.setMonth();
  }

  setMonth() {
    if (!
      (
        this.createEventForm.value.start_date &&
        this.createEventForm.value.end_date &&
        this.createEventForm.value.start_date &&
        this.createEventForm.value.end_date
      )
    ) return;
    this.months = [];
    const dateStart = momentJs(this.createEventForm.value.start_date.startDate._d);
    const dateEnd   = momentJs(this.createEventForm.value.end_date.startDate._d);
    const interim   = dateStart.clone();

    while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
      const monthName = interim.format('MMM');
      this.months.push({
        index: this.MomentDate.monthInt(monthName),
        name: monthName
      });
      interim.add(1, 'month');
    }
  }

  clearImage() {
    this.base64 = null;
    this.fileToUpload = null;
    this.imagePreview = '';
  }

  async dealWithFiles(ev) {
    this.fileToUpload = ev.target.files[0];
    this.base64 = await this._commonService.toBase64(this.fileToUpload) as string;

    const reader = new FileReader();
    reader.onload = e => {
      this.imagePreview = reader.result as string;
    }

    reader.readAsDataURL(this.fileToUpload);
  }

  getPresignedUrl() {
    if (!this.fileToUpload) return this.showAlertMsg('Please upload image', 'danger');
    if (!this.isFormValid()) return;

    const ext = this.fileToUpload.type.split('/')[1];
    this.loader = true;

    this._commonService
        .presignedUrl(this.charityId, this.createEventForm.value.event_identifier, ext)
        .subscribe(
          res => {
            this.imageUploadedData = res;
            this.myUploader();
          },
          err => this.showAlertMsg(err.message, 'danger', err.status)
        );
  }

  isFormValid() {
    this.createEventForm.markAllAsTouched();
    this.logValidationErrors();

    if (this.createEventForm.invalid) this.showAlertMsg(this.formErrorMsg, 'danger');
    return this.createEventForm.valid;
  }

  myUploader(): void {
    const blob = this._commonService.dataURItoBlob(this.base64);
    this._commonService
        .uploadImage(blob, this.fileToUpload.type, this.imageUploadedData.presigned_url)
        .subscribe(
          (res: any) => this.createEvent(),
          err => this.showAlertMsg(err.message, 'danger', err.status)
        );
  }

  createEvent() {
    const formData = this.createEventForm.value;

    const startDate = momentJs(formData.start_date.startDate._d).format('MM-DD-YYYY');
    const endDate   = momentJs(formData.end_date.startDate._d).format('MM-DD-YYYY');

    let optUrl = formData.opt_in_url as string;
    if (!optUrl.includes('http')) optUrl = 'https://' + optUrl;

    let groupUrl = formData.group_url as string;
    if (!groupUrl.includes('http')) groupUrl = 'https://' + groupUrl;

    let url50 = formData.activity_markers.fifty_activity_mark as string;
    if (!url50.includes('http')) url50 = 'https://' + url50;

    let url100 = formData.activity_markers.hundred_activity_mark as string;
    if (!url100.includes('http')) url100 = 'https://' + url100;

    const apiData = {
      event_name: formData.event_name,
      start_date: startDate,
      end_date: endDate,
      month: parseInt(formData.month, 10),
      fundraiser_goal: formData.fundraiser_goal,
      opt_in_url: optUrl,
      group_url: groupUrl,
      event_image_url: this.imageUploadedData.resource_id,
      event_identifier: formData.event_identifier,
      event_series_id: parseInt(formData.event_series_id, 10),
      activity_details: {
        activity_label_id: parseInt(formData.activity_details.activity_label, 10),
        activity_goal: formData.activity_details.activity_goal
      },
      activity_markers: {
        activity_mark_50: url50,
        activity_mark_100: url100
      }
    };

    this._eventService
        .createEvent(apiData, this.charityId)
        .subscribe(
          (res: any) => {
            this.showAlertMsg(res.message, 'success');
            setTimeout(() => {
              this.router.navigate(['/events']);
            }, 2000);
          },
          err => this.showAlertMsg(err.message, 'danger', err.status)
        );
  }

  showAlertMsg(msg, type, status = null): void {
    this.loader = false;
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;

    setTimeout(() => {
      this.alertMsg.msg   = '';
      this.alertMsg.type  = '';
    }, 3000);

    setTimeout(() => {
      if (status === 401) this.router.navigate(['/login']);
    }, 2000);
  }

  logValidationErrors(group: UntypedFormGroup = this.createEventForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl: AbstractControl = group.get(key);

      this.formErrors[key] = '';
      if (
        abstractControl &&
        !abstractControl.valid &&
        (abstractControl.touched || abstractControl.dirty)
      ) {
        const msg = this.validationMessages[key];

        for (const errorKey in abstractControl.errors) {
          if (errorKey) this.formErrors[key] = msg[errorKey] + ' ';
        }
      }

      if (abstractControl instanceof UntypedFormGroup) this.logValidationErrors(abstractControl);
    });

    for (const key in this.formErrors) {
      if (this.formErrors[key]) {
        this.formErrorMsg = 'Error in ' + key.replace(/_/g, ' ') + ' field: ' + this.formErrors[key];
        return;
      }
    }
  }

  initialiseCreateSeriesForm(){
    const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    this.createSeriesForm = this.fb.group({
     short_name: ['', [Validators.required]], 
     full_name: ['', [Validators.required]],
     //group_url: ['', [Validators.required, Validators.pattern(reg)]]
    })
   }

  get f() { return this.createSeriesForm.controls; }

  addSeries() {
    this.submitted = true;
    let apiData = {
      short_name: this.createSeriesForm.value.short_name,
      full_name: this.createSeriesForm.value.full_name,
      //group_url: this.createSeriesForm.value.group_url
    }

    this.crateSeriesSubscription = this._eventService.createSeries(apiData, this.charityId)
      .subscribe(
        (res: any) => {
          this.showAlertMsg(res.message, 'success');
          if(res.message){      
            this.createSeriesForm.reset();   
            this.createSeriesForm.controls['full_name'].setErrors(null);
            this.createSeriesForm.controls['short_name'].setErrors(null);     
            //this.createSeriesForm.controls['group_url'].setErrors(null);       
            const addSeriesBtn: HTMLElement = this.modalCloseAddSeries.nativeElement;
            addSeriesBtn.click();             
          }else{
            const addSeriesBtn: HTMLElement = this.modalCloseAddSeries.nativeElement;
            addSeriesBtn.click();
          }         
          setTimeout(() => {
            this.getEventListSeriesActivities();;
          }, 2000);
        },
        err => this.showAlertMsg(err.message, 'danger', err.status)
      );
  }

  initialiseActivity() {
     this.createActivityForm = this.fb.group({
      goal_type: ['', [Validators.required]], 
     })
  }

  get factivity() { return this.createActivityForm.controls; }

  addActivity(){
    this.submitted = true;
    let apiData = {
      goal_type: this.createActivityForm.value.goal_type  
    }  
    this.crateActivitySubscription = this._eventService.createActivityLabel(apiData, this.charityId)
    .subscribe(
      (res: any) => {
        this.showAlertMsg(res.message, 'success');
        if(res.message){      
          this.createActivityForm.reset();   
          this.createActivityForm.controls['goal_type'].setErrors(null);      
          const addActivityLableBtn: HTMLElement = this.modalCloseaddActivity.nativeElement;
          addActivityLableBtn.click();             
        }else{
          const addActivityLableBtn: HTMLElement = this.modalCloseaddActivity.nativeElement;
          addActivityLableBtn.click();
        }         
        setTimeout(() => {
          this.getEventListSeriesActivities();
        }, 1000);
      },
      err => this.showAlertMsg(err.message, 'danger', err.status)
    );

  }

  redirect(path: string) {
    this.router.navigate([path]);
  }

  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.crateSeriesSubscription) this.crateSeriesSubscription.unsubscribe();
  }
}
