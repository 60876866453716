import { Injectable } from '@angular/core';
import { ChartOptions } from '../models/ChartJs';
import { NgoSharedService } from '../shared/services/ngo-shared.service';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  public selectedNgo ;

  constructor(private _ngoSharedService: NgoSharedService) { 
    this.getSelectedNgoData();
  }

  getSelectedNgoData(): void {
    this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.selectedNgo = selectedNGO;
            }
          }
        );
  }
  
  public options: ChartOptions = {
    responsive: true,
    legend: {
      display: true,
      labels: {
        usePointStyle: true
      }
    },
    scales: {
      xAxes: [
        {
        display: true,
        stacked: true,
        gridLines: {
          drawBorder: true,
          drawOnChartArea: false,
        }
        }
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawOnChartArea: false,
          },
          ticks: {
            callback(value, index, values) {
              return '$' + value;
            }
          }
        }
      ],
    }
  };

  public activeEventOptions: ChartOptions = {
    responsive: true,
    hover: {
      intersect: true
    },
    legend: {
      onHover: (e) => {
        e.target.style.cursor = 'pointer';
      },
      display: false,
      labels: {
        usePointStyle: true,
        fontColor: 'rgba(0,44,69, .6)',
        fontSize: 12,
        fontStyle: 'bold',
        fontFamily: 'Poppins',
      }
    },
    tooltips: {
      mode: 'index',
      backgroundColor: 'rgba(0, 44, 69, .8)',
      footerFontStyle: 'normal',
      titleFontFamily: 'Poppins',
      titleFontSize: 12,
      titleMarginBottom: 10,
      bodyFontFamily: 'Poppins',
      bodyFontSize: 12,
      bodySpacing: 5,
      callbacks: {
        title: (tooltipItems, data) => {
          let sum = 0;
          tooltipItems.forEach((tooltipItem) => {
            if ( !isNaN(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) )
              sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          });
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
          return sum > 0 ? 'Total Raised: '+ currencySymbol + sum.toLocaleString() : null;
        },
        label: (tooltipItems, data) => {
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
          let label = data.datasets[tooltipItems.datasetIndex].label || '';
          if (label) label += ': '+ currencySymbol;
          if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
          else label += 0;
          return label;
        }
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            drawBorder: true,
            drawTicks: true,
            drawOnChartArea: false,
          }
        }
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawTicks: false,
            drawOnChartArea: false,
          },
          ticks: {
            callback: (value, index, values) => (this.selectedNgo.currency_name=='USD' ? ' $' : ' £') + this.formatNumber(value) + ' '
          },
        }
      ],
    }
  };

  public subscriptionGraphOptions: ChartOptions = {
    responsive: true,
    hover: {
      intersect: true
    },
    legend: {
      onHover: (e) => {
        e.target.style.cursor = 'pointer';
      },
      display: false,
      labels: {
        usePointStyle: true,
        fontColor: 'rgba(0,44,69, .6)',
        fontSize: 12,
        fontStyle: 'bold',
        fontFamily: 'Poppins',
      }
    },
    tooltips: {
      mode: 'index',
      backgroundColor: 'rgba(0, 44, 69, .8)',
      footerFontStyle: 'normal',
      titleFontFamily: 'Poppins',
      titleFontSize: 12,
      titleMarginBottom: 10,
      bodyFontFamily: 'Poppins',
      bodyFontSize: 12,
      bodySpacing: 5,
      callbacks: {
        title: (tooltipItems, data) => {
          let sum = 0;
          tooltipItems.forEach((tooltipItem) => {
            if (
              (data.datasets[tooltipItem.datasetIndex].label.includes('Direct') ||
              data.datasets[tooltipItem.datasetIndex].label.includes('Challenges')) &&
              (!isNaN(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]))
            ) {
              sum += parseInt(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 10);
            }
          });
          return 'Total: ' + sum.toLocaleString();
        },
        label: (tooltipItems, data) => {
          let label: string = data.datasets[tooltipItems.datasetIndex].label || '';
          label = label.split(' ')[0];
          if (label) label += ': ';
          if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
          else label += 0;
          return label;
        }
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            drawBorder: true,
            drawTicks: true,
            drawOnChartArea: false,
          }
        }
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: true,
            drawTicks: false,
            drawOnChartArea: false,
          },
          ticks: {
            callback: (value, index, values) => this.formatNumber(value) + ' '
          },
        }
      ],
    }
  };

  public fundraiserOptions: ChartOptions = {
    responsive: true,
    hover: {
      intersect: true
    },
    legend: {
      onHover: (e) => {
        e.target.style.cursor = 'pointer';
      },
      display: false,
      labels: {
        usePointStyle: true,
        fontColor: 'rgba(0,44,69, .6)',
        fontSize: 12,
        fontStyle: 'bold',
        fontFamily: 'Poppins',
      }
    },
    tooltips: {
      mode: 'index',
      intersect: true,
      backgroundColor: 'rgba(0, 44, 69, .8)',
      footerFontStyle: 'normal',
      titleFontFamily: 'Poppins',
      titleFontSize: 12,
      titleMarginBottom: 10,
      bodyFontFamily: 'Poppins',
      bodyFontSize: 12,
      bodySpacing: 5,
      callbacks: {
        title: (tooltipItems, data) => {
          let sum = 0;
          tooltipItems.forEach((tooltipItem) => {
            if (data.datasets[tooltipItem.datasetIndex].yAxisID === 'left-y-axis')
              sum += parseInt(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 10);
          });

          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
          return sum > 0 ? 'Total Raised: ' + currencySymbol + sum.toLocaleString() : null;
        },
        label: (tooltipItems, data) => {
          const axisId = data.datasets[tooltipItems.datasetIndex].yAxisID;
          let label = data.datasets[tooltipItems.datasetIndex].label || '';
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';


          if (label && axisId === 'left-y-axis') label += ': ' + currencySymbol;
          else label += ': ';

          if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
          else label += 0;

          return label;
        },
        footer: (tooltipItems, data) => {
        },
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            drawBorder: true,
            drawTicks: true,
            drawOnChartArea: false,
          },
          ticks: {
            z: 1000,
          }
        }
      ],
      yAxes: [],
    }
  };

  public retentionScore = {
    responsive: true,
    radius: 20,
    layout: {
      padding: {
        left: 80
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            drawBorder: true,
            drawOnChartArea: false,
          },
          ticks: {
            callback: (value, index, values) => this.formatNumber(value)
          },
        }
      ],
      yAxes: [
        {
          ticks: {
            mirror: true,
            padding: 80
          },
          display: true,
          stacked: true,
          gridLines: {
            display: false,
            drawBorder: true,
            drawOnChartArea: false,
          }
        }
      ],
    }
  };

  public eventlistOptions: ChartOptions = {
    responsive: true,
    hover: {
      intersect: false
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        fontColor: 'rgba(0,44,69, .6)',
        fontSize: 12,
        fontStyle: 'bold',
        fontFamily: 'Poppins',
      }
    },
    tooltips: {
      mode: 'index',
      backgroundColor: 'rgba(0, 44, 69, .8)',
      footerFontStyle: 'normal',
      titleFontFamily: 'Poppins',
      //titleFontSize: 12,
      //titleMarginBottom: 10,
      bodyFontFamily: 'Poppins',
      //bodyFontSize: 12,
      //bodySpacing: 5,
      position:'nearest',
      //yAlign:'bottom',
      callbacks: {
        title: (tooltipItems, data) => {
          let sum = 0;
          tooltipItems.forEach((tooltipItem) => {
            if (data.datasets[tooltipItem.datasetIndex].yAxisID === 'left-y-axis') {
              sum += parseInt(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 10);
            }
          });
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
          return sum > 0 ? 'Total Raised: ' + currencySymbol + sum.toLocaleString() : null;
        },
        label: (tooltipItems, data) => {
          const axisId = data.datasets[tooltipItems.datasetIndex].yAxisID;
          let label = data.datasets[tooltipItems.datasetIndex].label || '';
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
          

          if (label && axisId === 'left-y-axis') label += ': ' + currencySymbol;
          else if (label === 'Total Campaigns') label = 'Campaigns: ';
          else if (label === 'Total Donations') label = 'Donations: ';

          if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
          else label += 0;
          return label;
        },
        footer: (tooltipItems, data) => {
        },
      },
    },
    scales: {
      xAxes: [
        {
        display: true,
        stacked: true,
        gridLines: {
          drawBorder: true,
          drawTicks: true,
          drawOnChartArea: false,
        }
        }
      ],
      yAxes: [],
    }
  };

  public eventSeriesOptions: ChartOptions = {
    responsive: true,
    hover: {
      intersect: true
    },
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        fontColor: 'rgba(0,44,69, .6)',
        fontSize: 12,
        fontStyle: 'bold',
        fontFamily: 'Poppins',
      }
    },
    tooltips: {
      mode: 'index',
      backgroundColor: 'rgba(0, 44, 69, .8)',
      footerFontStyle: 'normal',
      titleFontFamily: 'Poppins',
      titleFontSize: 12,
      titleMarginBottom: 10,
      bodyFontFamily: 'Poppins',
      bodyFontSize: 12,
      bodySpacing: 5,
      callbacks: {
        title: (tooltipItems, data) => {
          let sum = 0;
          tooltipItems.forEach((tooltipItem) => {
            if (data.datasets[tooltipItem.datasetIndex].yAxisID === 'left-y-axis') {
              sum += parseInt(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index], 10);
            }
          });
          return null;
          // return sum > 0 ? 'Total Raised: $' + sum.toLocaleString() : null;
        },
        label: (tooltipItems, data) => {
          const axisId = data.datasets[tooltipItems.datasetIndex].yAxisID;
          let label = data.datasets[tooltipItems.datasetIndex].label || ''; 
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';

          if (label && axisId === 'left-y-axis') label += ': ' + currencySymbol;
          else
          label += ': ';
          // else if (label === 'Total Campaigns') label = 'Campaigns: ';
          // else if (label === 'Total Donations') label = 'Donations: ';

          if (!isNaN(tooltipItems.yLabel)) label += (Math.round(tooltipItems.yLabel * 100) / 100).toLocaleString();
          else label += 0;
          return label;
        },
        footer: (tooltipItems, data) => {
        },
      },
    },
    scales: {
      xAxes: [
        {
        display: true,
        stacked: true,
        gridLines: {
          drawBorder: true,
          drawTicks: true,
          drawOnChartArea: false,
        }
        }
      ],
      yAxes: [],
    },
    elements: {
      point: {
          radius: 2
      },
      line: {
        tension: 0
      }
    }
  };

  formatNumber(labelValue) {
    const sign = Math.sign(Number(labelValue));

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? sign * Math.abs(Number(labelValue)) / 1.0e+9 + 'b'
    // Six Zeroes for Millions
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? sign * Math.abs(Number(labelValue)) / 1.0e+6 + 'm'
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? sign * Math.abs(Number(labelValue)) / 1.0e+3 + 'k'

    : sign * Math.abs(Number(labelValue));
  }

  createYaxesConfig () {
    return {
      'left-y-axis': {
        min: 0,
        max: 1
      },
      'right-y-axis': {
        min: 0,
        max: 1
      },
      totalTicks: 11
    }
  }

  minValue = (arr: number[]) => Math.min(...arr);
  
  maxValue = (arr: number[]) => Math.max(...arr);

  hasAlmostEqual(x: number = 0, y: number = 0, epsilon: number = 0) {
    return Math.abs(x - y) < epsilon;
  }

  updateYaxesMinMax (refObject: any, key: string, array: number[], defaultMin: number = 0, defaultMax: number = 1) {
    const min = this.minValue([...array, defaultMin]);
    const max = this.maxValue([...array, defaultMax]);
    if (refObject[key].min > min) refObject[key].min = min;
    if (refObject[key].max < max) refObject[key].max = max;
  };

  getTickSpacing(range: number = 0, multiple: number = 1) {
    const exponent = Math.floor(Math.log10(range));
    const fraction = range / Math.pow(10, exponent);
    let niceFraction: number;

    if (fraction <= 1.0) {
      niceFraction = 1;
    } else if (fraction <= 2) {
      niceFraction = 2;
    } else if (fraction <= 5) {
      niceFraction = 5;
    } else {
      niceFraction = 10;
    }
    return niceFraction * Math.pow(10, exponent) * multiple;
  }

  getDecimalPlace(x: number) {
    let e = 1;
    let p = 0;
    while (Math.round(x * e) / e !== x) {
      e *= 10;
      p++;
    }
    return p;
  }

  getPreCalculatedTickConfig = (data: any, maxNumSpaces: number = 10) => {
    const min = data.min;
    const max = data.max;
    const MIN_SPACING = 1e-14;
	  const unit = 1;

    let spacing = this.getTickSpacing((max - min) / maxNumSpaces / unit, unit);

    if (spacing < MIN_SPACING && !min && !max) {
      // Rare case
      return {
        min: min,
        max: max,
        numSpaces: 1,
        spacing: spacing
      }
    }
    
    let numSpaces = Math.ceil(max / spacing) - Math.floor(min / spacing);

    if (numSpaces > maxNumSpaces) {
      spacing = this.getTickSpacing(numSpaces * spacing / maxNumSpaces / unit, unit);
    }
    const factor = Math.pow(10, this.getDecimalPlace(spacing));
    let niceMin = Math.floor(min / spacing) * spacing;
    let niceMax = Math.ceil(max / spacing) * spacing;
    numSpaces = (niceMax - niceMin) / spacing;

    if (this.hasAlmostEqual(numSpaces, Math.round(numSpaces), spacing / 1000)) {
      numSpaces = Math.round(numSpaces);
    } else {
      numSpaces = Math.ceil(numSpaces);
    }

    niceMin = Math.round(niceMin * factor) / factor;
    niceMax = Math.round(niceMax * factor) / factor;
    if (spacing < 1) {

      const subFactor = Math.ceil(1/spacing);
      niceMin *= subFactor;
      niceMax *= subFactor;
      spacing *= subFactor;
    }

    return {
      min: niceMin,
      max: niceMax,
      numSpaces: numSpaces,
      spacing: spacing
    }
  }

  equalizeTickConfig(left: any, right: any) {
    const noOfTick = (tick: any, key: string) => Math.abs((tick[key] || 0) - 0)/tick?.spacing;
    const prefferedUpperHalf = { noOfTick: 0, tickSpace: 0, key: '' };
    const prefferedLowerHalf = { noOfTick: 0, tickSpace: 0, key: '' };

    const compareValue = (noOfTick: number, tickSpace: number, key: string, copyObject: any) => {
       const copyValues = () => {
        copyObject.noOfTick = noOfTick;
        copyObject.tickSpace = tickSpace;
        copyObject.key = key;
       }

       switch(true) {
        case !copyObject?.key:
        case copyObject.noOfTick < noOfTick:
        case (copyObject.noOfTick === noOfTick) && (copyObject.tickSpace < tickSpace):
          copyValues();
          break;
        default:
          break;
       }
    }

    const upperLeftTick = noOfTick(left, 'max');
    compareValue(upperLeftTick, left.spacing, 'upperLeftTick', prefferedUpperHalf);

    const upperRightTick = noOfTick(right, 'max');
    compareValue(upperRightTick, right.spacing, 'upperRightTick', prefferedUpperHalf);


    const bottomLeftTick = noOfTick(left, 'min');
    compareValue(bottomLeftTick, left.spacing, 'bottomLeftTick', prefferedLowerHalf);

    const bottomRightTick = noOfTick(right, 'min');
    compareValue(bottomRightTick, right.spacing, 'bottomRightTick', prefferedLowerHalf);
    

    left.max = prefferedUpperHalf?.noOfTick * left?.spacing;
    right.max = prefferedUpperHalf?.noOfTick * right?.spacing;
    left.min = -prefferedLowerHalf?.noOfTick * left?.spacing;
    right.min = -prefferedLowerHalf?.noOfTick * right?.spacing;
  }
}
