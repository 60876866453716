export const environment = {
  production: false,
  apiEndpoint: "https://app-stage.goodunited.io/api/blackbaud/",
  apiEndpointReact: "https://app-stage.goodunited.io/",
  baseURL: "https://app-stage.goodunited.io/",
  newDashboardURL: "https://app-stage.goodunited.io/n/",
  apiUrl: "https://api.app-stage.goodunited.io/",
  localKey: "gu-",
  fbAppKey: "196204240716599",
  pendoKey: "b2487908-5de4-4d03-5994-52a295c14d3b",
  showNewreport: true,
  logRocket: {
    key: "b3lndb/goodunited-webapp",
    envType: "stage",
    enable: true
  }
};
