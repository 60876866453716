import { Component, OnDestroy, OnInit, ViewChild, ElementRef, HostListener, Inject} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { Subscription } from 'rxjs';
import { SelectedNgo } from 'src/app/models/SelectedNgo.model';
import { ChartsService } from 'src/app/services/charts.service';
import { EventService } from 'src/app/services/event.service';
import { FilterSharedService } from 'src/app/shared/services/filter-shared.service';
import { NgoSharedService } from 'src/app/shared/services/ngo-shared.service';
import { Filter } from 'src/app/models/Fundraiser';
import { EventList, ChartColor, FilterApplied, ChartColorLine, ChartLegends, ChartOrder } from 'src/app/models/Event';
import * as momentJs from 'moment';
import { finalize } from 'rxjs/operators';
import { UserSharedService } from 'src/app/shared/services/user-shared.service';
import { FormatNumber } from 'src/app/shared/custom/FormatNumber';
import { Sort } from '@angular/material/sort';
import { DOCUMENT } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'app-event-series',
  templateUrl: './event-series.component.html',
  styleUrls: ['./event-series.component.scss']
})
export class EventSeriesComponent implements OnInit, OnDestroy {
  @ViewChild('modalCloseBtnYear') modalCloseBtnYear: ElementRef<HTMLElement>;
  @ViewChild('modalCloseBtnMonth') modalCloseBtnMonth: ElementRef<HTMLElement>;

  public selectedNgo: SelectedNgo;
  public charityId: string;
  public charityIDSubscription: Subscription;

  public chart;
  public chartColor       = ChartColor;
  public chartColorLine   = ChartColorLine;
  public chartLegends     = ChartLegends;
  public chartOrder       = ChartOrder;
  public chartData: any[] = [];

  public eventSeriesData: EventList;

  public currentYear  = new Date().getFullYear();
  public currentMonth = momentJs().month() +1;

  public filterYear   = Array.from({length: this.currentYear - 2017}, (v, k) => this.currentYear - k);
  public filterMonth  = momentJs.monthsShort();

  public filterData: Filter;
  public filterDataSubscription: Subscription;
  public seriesID: any;
  public subscribeData: any;

  public loading = true;
  public activeSubscriberLoader = true;
  public subscriberlistData: any;
  public eventSeriesListingLoader = true;
  public eventSeriesListingData: any[] = [];
  public sortedData: any[] = [];
  public user: any;
  public roles: any;
  public userRole: any;
  public userSubscription: Subscription;
  public filterApplied = FilterApplied;
  public formatNumber = new FormatNumber();
  public searchTxt: any | undefined;
  public totalSearchResult: any;
  public searchSubscription: Subscription;
  public searchStatus: string;

  public sortingField: string;
  public orderBy: string;

  public config: any;
  public totalRecords: number;
  public currentPageCount: number;
  // public datasetFlag = {
  //   Campaigns: true,
  //   Donations: true,
  // };

  // public datasets = {
  //   Campaigns: null,
  //   Donations: null,
  // };
  public datasetFlag = {
    'Total Campaigns': true,
    'Total Donations': true,
  };

  public datasets = {
    'Total Campaigns': null,
    'Total Donations': null,
  };
  public alertMsg = { type: '', msg: ''};

  public avgCampaign = 0;
  public avgDonation = 0;
  public avgDonations = 0;
  public currencyName:string;

  positionOptions: TooltipPosition[] = ['after', 'before', 'above', 'below', 'left', 'right'];
  positionLeft = new UntypedFormControl(this.positionOptions[4]);
  positionTop = new UntypedFormControl(this.positionOptions[2]);
  positionBottom = new UntypedFormControl(this.positionOptions[3]);
  checkedViewGraph = [];
  removeData = [];


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _ngoSharedService: NgoSharedService,
    private _filterSharedService: FilterSharedService,
    private _eventService: EventService,
    private _chartService: ChartsService,
    private _userSharedService: UserSharedService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.config = {
      itemsPerPage: 10,
      currentPage: 1,
      totalItems: this.totalRecords
    };
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 30 ||     
    document.documentElement.scrollTop > 100) {
      document.getElementById('subscriberList').classList.add('sideContent');
    }else{
      document.getElementById('subscriberList').classList.remove('sideContent');
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getUserinfo();
    this.getCharityId();   
  }

  getUserinfo() {
    this.userSubscription = this._userSharedService
        .userData
        .subscribe(
          res => {
            if (Object.keys(res).length > 0) {
               this.user = res;
               this.roles = this.user.roles;
               this.userRole = this.roles[0].role;
            }
          }
        );
  }

  getSeriesIdData() {
    this.subscribeData = this.route.params.subscribe(params => {
      this.seriesID = +params.event_series_id;
    });  
  }

  ngoChanged(ngo: SelectedNgo) {
    this.router.navigate(['/']);
  }

  getCharityId(): void {
    this.charityIDSubscription = this._ngoSharedService
        .selectedNgo
        .subscribe(
          selectedNGO => {
            if (selectedNGO.charityId !== null) {
              this.currencyName = selectedNGO.currency_name;
              this.selectedNgo = selectedNGO;
              this.charityId  = selectedNGO.charityId;
              if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
              this.getSeriesIdData();
              //this.NewEventSeriesData();
              this.getDateFilter();
            }
          }
        );
  }

  getDateFilter() {
    this.filterDataSubscription = this._filterSharedService
        .dateFilters$
        .subscribe(
          res => {
            this.filterData = res;
            this.eventSeriesData = null;
            if (this.chart) this.chart.destroy();

            this.avgCampaign = this.avgDonation = this.avgDonations = 0;
            
            this.eventSeriesList();
            this.getOnlyEventSeriesListData();
            this.getEventSeriesSubscriberListData();              
            (this.searchTxt === undefined || this.searchTxt === ' ')  ? ' ' : this.getEventSeriesSearchData();

          }
        );
  }

  getOnlyEventSeriesListData() {
    this.eventSeriesListingLoader = true;
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
    this.searchSubscription = this._eventService
        .onlyEventSeriesList(this.charityId, this.filterData, this.seriesID)
        .subscribe(
          (res: any) => {
            this.eventSeriesListingLoader = false;
            this.sortingField = 'name';
            this.orderBy      = 'asc';

            this.eventSeriesListingData = res && res.events_listing ? res.events_listing : [];

            if (this.eventSeriesListingData && this.eventSeriesListingData.length > 0)
              this.eventSeriesListingData = this.eventSeriesListingData.sort((a, b) => this.compare(a.name, b.name, true));
          },
          err => {
            this.eventSeriesListingData = null;
            this.eventSeriesListingLoader = false;
          }
        );
  }

  getEventSeriesSubscriberListData() {
    this.activeSubscriberLoader = true;
    this._eventService
        .eventSeriesSubscirberList(this.charityId, this.filterData, this.seriesID)
        .pipe(finalize(() => this.activeSubscriberLoader = false))
        .subscribe(
          (res: any) => {
              if (typeof res === 'string') this.subscriberlistData = [];
              this.subscriberlistData = res.map((val) => {
                const newData = Object.assign({}, val);
                newData.fundraiser_amount = this.formatNumber.format(val.fundraiser_amount);
                return newData;
              });
          }
        );
  }

  eventSeriesList() {
    this.loading = true;
    this._eventService
        .eventSeries(this.charityId, this.filterData, this.seriesID)
        .pipe(finalize(() => {
          this.loading = false;
          this.createChart();
        }))
        .subscribe(
          (res: any) => {
            this.eventSeriesData = res;
            //console.log(this.eventSeriesData);
            if(this.eventSeriesData.data){
              this.eventSeriesData.data.forEach((val, i) => this.datasetFlag[val.title] = true);
              //console.log(this.eventSeriesData.data);
            }
            if(this.eventSeriesData.overall_result_dict.totalCampaign)
            this.avgCampaign = Math.round((this.eventSeriesData.overall_result_dict.totalRaised / this.eventSeriesData.overall_result_dict.totalCampaign));
            if(this.eventSeriesData.overall_result_dict.totalDonation)
            this.avgDonation = Math.round((this.eventSeriesData.overall_result_dict.totalRaised / this.eventSeriesData.overall_result_dict.totalDonation));
            if(this.eventSeriesData.overall_result_dict.totalCampaign)
            this.avgDonations = Math.round((this.eventSeriesData.overall_result_dict.totalDonation / this.eventSeriesData.overall_result_dict.totalCampaign));
          },
          err => {
            if (err.status === 400) this.showAlertMsg(err.message.series_id[0], 'danger');
            this.eventSeriesData = null;
          }
        );
  }

  createChart() {
    this.getDataSets();

    const labels = this.getLabels();

    if (this.chartData.length < 1) return;

    this.chart = new Chart('eventSeriesList', {
      type: 'bar',
      data: {
        labels,
        datasets: this.chartData
      },
      options: this._chartService.eventSeriesOptions
    });
  }

  getLabels() {
    if (!this.eventSeriesData || !this.eventSeriesData.hasOwnProperty('label') || !this.eventSeriesData.label) return [];

    const labels = [];
    this.eventSeriesData.label.forEach(val => {
      const label = val;
      labels.push(label);
    });

    return labels;
  }

  getDataSets() {
    const yAxesConfig = this._chartService.createYaxesConfig();
    this.chartData = [];
    if (!this.eventSeriesData || !this.eventSeriesData.hasOwnProperty('data') || !this.eventSeriesData.data) return;
    
    this._chartService.eventSeriesOptions.scales.yAxes = [];
    let order = this.eventSeriesData.data.length;

    this.eventSeriesData.data.forEach((val, i) => {

      if (!this.datasetFlag[val.title]) {
        return;
      }

      const data = {
        label: val.title,
        data: val.value,
        backgroundColor: this.chartColor[i],
        borderColor: this.chartColor[i],
        fill: true,
        barThickness: 6,
        type: '',
        borderDash: [],
        borderWidth: 0,
        lineTension: 0,
        yAxisID: '',
        order
      };

      if (
        val.type === 'line' &&
        (
          val.title === 'Total Campaigns' ||
          val.title === 'Total Donations'
        )
      ) {
        data.order            = this.chartOrder[val.title];
        data.label            = this.chartLegends[val.title];
        data.backgroundColor  = this.chartColorLine[val.title];
        data.borderColor      = this.chartColorLine[val.title];
        data.type             = val.type;
        data.fill             = false;
        data.borderDash       = val.title === 'Total Campaigns' ? [5, 5] : [];
        data.borderColor      = '#002C45';
        data.borderWidth      = 1;
        data.yAxisID          = 'right-y-axis';

        
        //if (val.title === 'Total Campaigns') this.setRightYAxis('right-y-axis', 'right', false);
        this.setRightYAxis('right-y-axis', 'right', false, val.title);
        this._chartService.updateYaxesMinMax(yAxesConfig, 'right-y-axis', data?.data);
      } else {
        this.setRightYAxis('left-y-axis', 'left', true, val.title);
        data.yAxisID    = 'left-y-axis';
        data.borderColor = 'rgba(255, 255, 255, 0.9)';
        this.datasetFlag[val.title] = true;
        this._chartService.updateYaxesMinMax(yAxesConfig, 'left-y-axis', data?.data);
      }

      order--;
      this.chartData.push(data);

      this.datasets[val.title] = null;
      //this.datasetFlag[val.title] = true;
    });
    this.setCustomTickConfig(yAxesConfig);
  }

  getTotalValue(title: string) {
    if (!(this.eventSeriesData && this.eventSeriesData.hasOwnProperty('data') && this.eventSeriesData.data)) return;

    let sum = 0;
    const data: any = this.eventSeriesData.data.filter(val => val.title === title);
    if (data.length < 1) return;

    data[0].value.forEach(val => {
      if (!isNaN(val)) sum += parseInt(val, 10);
    });

    if (title === 'Total Campaigns' || title === 'Total Donations') return ' ' + sum.toLocaleString();
    let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
    return currencySymbol + Math.round(sum).toLocaleString();
  }

  setRightYAxis(id: string, position: string, stacked: boolean, title:string) {
    this._chartService.eventSeriesOptions.scales.yAxes.push({
      id,
      stacked,
      type: 'linear',
      display: true,
      position,
      gridLines: {
        display: true,
        drawBorder: true,
        drawTicks: false,
        drawOnChartArea: false
      },
      ticks: {
        beginAtZero: false,
        callback: (value, index, values) => {
          let currencySymbol = this.selectedNgo.currency_name=='USD' ? ' $' : ' £';
          if (stacked) return currencySymbol + this._chartService.formatNumber(value) + ' ';
          else return ' ' + this._chartService.formatNumber(value);
        }
      },
    });
  }

  removeDataset(label: string) {
    this.removeData[label] = false;  
    this.datasetFlag[label] = false;
    this.chart.destroy();
    this.createChart();  
    // this.datasetFlag[label] = false;
    // this.datasets[label] = this.chart.data.datasets.filter((dataset) => (dataset.label === label))[0];
    // this.chart.data.datasets = this.chart.data.datasets.filter((dataset) => (dataset.label !== label));
    // this.chart.update();
  }

  addDataset(label: string) {
    this.removeData[label] = true;
    this.datasetFlag[label] = true;   
    this.chart.destroy();
    this.createChart(); 

    // this.datasetFlag[label] = true;
    // this.chart.data.datasets.push(this.datasets[label]);
    // this.chart.update();
  }

  filter(type: string, year = null, month = null) {
    const yearBtn: HTMLElement = this.modalCloseBtnYear.nativeElement;
    yearBtn.click();

    const monthBtn: HTMLElement = this.modalCloseBtnMonth.nativeElement;
    monthBtn.click();

    this.filterData.type  = type;
    this.filterData.year  = year === undefined ? null : year;
    this.filterData.month = month === undefined ? null : month;

    this._filterSharedService.changeDateFilter(this.filterData);
  }

  searchThis(SearchValue) {
    this.searchTxt = SearchValue;
    if (this.searchTxt.length >= 3) this.getEventSeriesSearchData();
    if (this.searchTxt === '' || this.searchTxt <= 2) {
      this.getOnlyEventSeriesListData();
      this.totalSearchResult = '';
    }
  }

  getEventSeriesSearchData() {
    this.totalSearchResult = '';
    this.searchStatus = 'Searching...';
    this.eventSeriesListingLoader = true;

    if (this.searchSubscription) this.searchSubscription.unsubscribe();

    this.searchSubscription = this._eventService
        .eventSeriesSearch(this.charityId, this.filterData, this.seriesID, this.searchTxt)
        .subscribe(
          (res: any) => {
            this.sortingField = 'name';
            this.orderBy      = 'asc';
            this.eventSeriesListingData = res.events_listing;
            if (this.eventSeriesListingData)
              this.eventSeriesListingData = this.eventSeriesListingData.sort((a, b) => this.compare(a.name, b.name, true));

            this.eventSeriesListingLoader = false;
            this.searchStatus = 'No Result';
            if (!this.eventSeriesListingData) return;
            this.totalRecords = res.events_listing.length;
            this.config.totalItems = this.totalRecords;
            this.currentPageCount = this.config.itemsPerPage * (this.config.currentPage - 1) + this.eventSeriesListingData.length;
            this.totalSearchResult = this.eventSeriesListingData.length;
          },
          err => {
            this.eventSeriesListingData = null;
            this.eventSeriesListingLoader = false;
          }
        );
  }

  toBottom() {
    document.getElementById('event-series-list').scrollIntoView();
  }

  newEvent() {
    this.router.navigate(['/events/new']);
  }

  redirect(ev) {
    this.router.navigate(['/events/detail', ev.event_id]);
  }

  redirectChallenges(){
    this.router.navigate(['/events']);
  }

  openSubscriberDetail(_id){  
    this.router.navigate(['/subscribers/detail', _id]);
  }

  sortData(sort: Sort) {
    this.sortingField = sort.active;
    this.orderBy      = sort.direction;
    const data = this.eventSeriesListingData && this.eventSeriesListingData.length > 0 ? this.eventSeriesListingData.slice() : [];

    if (!sort.active || sort.direction === '') return this.eventSeriesListingData = data;

    this.eventSeriesListingData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'start_date': return this.compare(a.start_date, b.start_date, isAsc, true, false);
        case 'raised': return this.compare(a.raised, b.raised, isAsc);
        case 'participants': return this.compare(a.participants, b.participants, isAsc);
        case 'group_members_count': return this.compare(a.group_members_count, b.group_members_count, isAsc);
        case 'fundraisers': return this.compare(a.fundraisers, b.fundraisers, isAsc);
        case 'average': return this.compare(a.raised_per_fund, b.raised_per_fund, isAsc);
        case 'donations': return this.compare(a.donations, b.donations, isAsc, false, true);
        case 'opt_in_per': return this.compare(a.opt_in_per, b.opt_in_per, isAsc, false, true);
        default: return 0;
      }
    });

  }

  compare(a: number | string, b: number | string, isAsc: boolean, isDate = false, isNumber = false) {
    if (isDate) {
      if (a == '-') a = 1970;
      if (b == '-') b = 1970;

      a = new Date(a).getTime();
      b = new Date(b).getTime();
    } else if (typeof(a) === 'string' && typeof(b) === 'string') {
      if(isNumber){
        a = +a.toLowerCase();
        b = +b.toLowerCase();      
      }else{
        a = a.toLowerCase();
        b = b.toLowerCase();
      }
    }
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
 
  ngOnDestroy() {
    if (this.charityIDSubscription) this.charityIDSubscription.unsubscribe();
    if (this.filterDataSubscription) this.filterDataSubscription.unsubscribe();
    if (this.searchSubscription) this.searchSubscription.unsubscribe();
  }

  showAlertMsg(msg, type): void {
    this.alertMsg.msg   = msg;
    this.alertMsg.type  = type;

    setTimeout(() => this.alertMsg.msg = '', 3000);
  }

  setCustomTickConfig (yAxesConfig: any) {
	  const maxNumSpaces = yAxesConfig?.totalTicks - 1;
    const leftYaxesConfig = this._chartService.getPreCalculatedTickConfig(yAxesConfig['left-y-axis'], maxNumSpaces);
    const rightYaxesConfig = this._chartService.getPreCalculatedTickConfig(yAxesConfig['right-y-axis'], maxNumSpaces);
    this._chartService.equalizeTickConfig(leftYaxesConfig, rightYaxesConfig);

    for (let i = 0; i < this._chartService.eventSeriesOptions.scales.yAxes?.length; i++) {
      const item = this._chartService.eventSeriesOptions.scales.yAxes[i];
      if (item?.id === 'left-y-axis') {
        this._chartService.eventSeriesOptions.scales.yAxes[i].ticks.min = leftYaxesConfig?.min;
        this._chartService.eventSeriesOptions.scales.yAxes[i].ticks.max = leftYaxesConfig?.max;
        this._chartService.eventSeriesOptions.scales.yAxes[i].ticks.stepSize = leftYaxesConfig?.spacing;
      } else if (item?.id === 'right-y-axis') {
        this._chartService.eventSeriesOptions.scales.yAxes[i].ticks.min = rightYaxesConfig?.min;
        this._chartService.eventSeriesOptions.scales.yAxes[i].ticks.max = rightYaxesConfig?.max;
        this._chartService.eventSeriesOptions.scales.yAxes[i].ticks.stepSize = rightYaxesConfig?.spacing;
      }
    }

  }

}
