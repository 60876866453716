import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable,SubscribableOrPromise } from 'rxjs';
import { DonorColumnKeys, ReportName, Reports, ReportType } from '../models/Report';
import { count } from 'console';

@Injectable({
    providedIn: 'root'
})

export class ReportService {

    constructor(
        private http: HttpClient,
    ) { }

    getFundraiserColumns(charityId: string): Observable<Reports> {
        const params = {
          charity_id: charityId  
        }
        const path = 'fundraiserreport/columns';
        return this.http.get<Reports>(path, { params });
    }

    getDonorColumns(charityId: string): Observable<DonorColumnKeys> {
        const params = {
            charity_id: charityId  
        }
        const path = 'donationreport/columns?';
        return this.http.get<DonorColumnKeys>(path, { params });
    }

    updateFundraiserColumns(charityId: string, page: any, columns:string, reportType:string, orderByAsc: string, orderByDsc:string,  emailsOnlyFlag: any, fromDate:string, toDate:string, searchTxt:string): Observable<any> {
        const params = {
            charity_id: charityId,
            page: page,
            columns: columns,
            report_type: reportType,
            order_by_asc: orderByAsc,
            order_by_dsc: orderByDsc,
            emails_only_flag: emailsOnlyFlag,
            search: searchTxt 
        };

        if (fromDate) params['from_date'] = fromDate;
        if (toDate) params['to_date'] = toDate;

        const path = 'report';
        return this.http.get(path, { params });
    }

    getSelectedReportTaskID(charityId: string, data,emailOnly): Observable<any> {
        let headers = new HttpHeaders()
        const path = 'export_reporting_data';
        let params :any = {
            charity_id: charityId,
        }
        if(data.report_id?.length == 1){
            params.email_only = emailOnly || false 
        }
        return this.http.post(path, data, { headers: headers, params });
    }

    getReportDownloadLinks(charityId: string, taskID): Observable<any> {
        const params = {charity_id: charityId, task_id: taskID};
        return this.http.get('get_report_download_link_reporting', { params });
    }

    exportCsv(charityId: string, data): Observable<any> {
        let headers = new HttpHeaders()
        const path = 'report_csv_download';
        const params = {
            charity_id: charityId  
        }
        return this.http.post(path, data, { headers: headers,responseType: 'text', params });
    }

    recentReportId(charityId: string): Observable<any> {
        const params = {charity_id: charityId};
        return this.http.get('get_recent_report_id', { params });
    }

    reportDownloadLink(charityId: string, reportId: string): Observable<any> {        
        const params = {
            charity_id: charityId,
            report_id: reportId,
        };
        return this.http.get('get_report_download_link', { params });
    }

    generateReport(charityId: string, data): Observable<any> {
        let headers = new HttpHeaders()
        const path = 'generate_report';
        const params = {
            charity_id: charityId  
        }
        return this.http.post(path, data, { headers: headers,responseType: 'text', params });
    }

    savedReports(params): Observable<any> {
        return this.http.get('reportsearch', { params });
    }

    deleteReports(charityId: string, report_id:any): Observable<any> {
        let params = new HttpParams();
        params = params.append('charity_id', charityId);
        const data = {
            report_id: report_id,
        }
        return this.http.post('deleteReport', data , { params });      
    }

    // For new report page service functions 

    getReportName(charityId: string): Observable<ReportName> {
        const params = {
            charity_id: charityId
        }
        const path = 'reportName';
        return this.http.get<ReportName>(path, { params });
    }

    getReportType(charityId: string): SubscribableOrPromise<ReportType> {
        const params = {
            charity_id: charityId
        }
        const path = 'reportType';
        return this.http.get<ReportType>(path, { params }).toPromise();
    }

    getReportFilter(charityId: any, reportType: any): SubscribableOrPromise<any> {
        const params = {
            charity_id: charityId,
            report_type: reportType
        }
        const path = 'filters';
        return this.http.get<any>(path, { params }).toPromise();
    }

    getSavedReport(params): SubscribableOrPromise<any> {        
        const path = 'getSavedReport';
        return this.http.get<any>(path, { params }).toPromise();
    }

    getReportColumn(filterType: any, reportType: any, charityId: any): Observable<any> {
        const params = {
            filter: filterType,            
            report_type: reportType,
            charity_id: charityId
        }
        const path = 'columns';
        return this.http.get<any>(path, { params });
    }

    getPreviewFilterService(params: any, payload:any): Observable<any> {        
        payload.columns = [...new Set(payload.columns)];     
        const path = 'getReports';     
        return this.http.post(path, payload, { params });
    }

    // getNextPaginationSerivce(charityId:string, count:any, report_type:string, filter:string, data:any, fromDate:string, toDate:string, search:any, emailOnly:boolean, page:string): Observable<any>{
    //     let params:any = {};
    //      params = {           
    //         charity_id: charityId,
    //         count:count,
    //         report_type:report_type,
    //         filter:filter,
    //         search:search,
    //         email_only: emailOnly || false,
    //         page:page
    //     }
    //     if(fromDate !== '' && toDate !== ''){

    //         params.from_date = fromDate;
    //         params.to_date = toDate;
    //     }
    //     const path = 'getReports';     
    //     return this.http.post(path, data, {params});
    // }

    // getPrevPaginationSerivce(charityId:string, count:any, report_type:string, filter:string, beforeKeys:string, data:any, fromDate:string, toDate:string, search:any, emailOnly:boolean): Observable<any>{
    //     let params:any = {};
    //     params = {           
    //         charity_id: charityId,
    //         count:count,
    //         report_type:report_type,
    //         filter:filter,
    //         search:search,
    //         before:beforeKeys,
    //         email_only: emailOnly || false 
    //     }
    //     if(fromDate !== '' && toDate !== ''){   
    //         params.from_date = fromDate;
    //         params.to_date = toDate;
    //     }
    //     const path = 'getReports';     
    //     return this.http.post(path, data, {params});
    // }

    newSaveReport(charityId:string, data,emailOnly,filterCondition): Observable<any> {
        const params = {           
            charity_id: charityId,
            email_only:emailOnly || false,
            condition_type: filterCondition
        }
        const path = 'save_report';     
        return this.http.post(path, data, {params});
    }

    updateReport(charityId:string,reportId:string ,data,emailOnly,filterCondition): Observable<any> {
        const params = {           
            charity_id: charityId,
            report_id: reportId,
            email_only:emailOnly || false,
            condition_type: filterCondition
        }
        const path = 'save_report';     
        return this.http.put(path, data, {params});
    }

    // New reporting menu using reporting flag
    reportingFlag(charityId: any): SubscribableOrPromise<any> {
        const params = {           
            charity_id: charityId
        }
        const path = 'get_reporting_flag'; 
        return this.http.request('get', path, { params }).toPromise();
      }

}    